import { ActionContext } from 'vuex'

import {
	IForm,
	ISubmissionConfiguration,
	IConfigurationOption,
} from '@/models/worklists/outcome'
import { IPlaceOfService } from '@server/api/models/fe/shared'
import { IPublishedPlanAssociation } from '@server/api/models/fe/planAssociation'

interface IWorklistState {
	form: IForm
	submission: ISubmissionConfiguration
	fields: {
		placesOfService: IPlaceOfService[]
	}
	plans: IPublishedPlanAssociation[]
	submissionConfigurations: any[]
}

const getInitialState = () => ({
	form: {
		id: '',
		name: '',
	},
	submission: {
		id: '',
		name: '',
	},
	fields: {
		placesOfService: [],
	},
	submissionConfigurations: [],
})

const mutations = {
	setForm(state: IWorklistState, form: IForm) {
		state.form = form
	},
	setSubmission(state: IWorklistState, submission: ISubmissionConfiguration) {
		state.submission = submission
	},
	setPlacesOfService(
		state: IWorklistState,
		placesOfService: IPlaceOfService[]
	) {
		state.fields.placesOfService = placesOfService
	},
	setPlans(state: IWorklistState, plans: IPublishedPlanAssociation[]) {
		state.plans = plans
	},
	setSubmissionConfiguration(state: IWorklistState, configuration: any) {
		const hasConfiguration =
			state.submissionConfigurations.includes(configuration)

		if (!hasConfiguration) {
			state.submissionConfigurations.unshift(configuration)
		}
	},
	setSubmissionConfigurations(state: IWorklistState, configurations: any[]) {
		state.submissionConfigurations = configurations
	},
}

const getters = {
	getForm(state: IWorklistState) {
		const form = state.form

		if (form.id && form.name) {
			return form
		}

		return null
	},
	getSubmission(state: IWorklistState) {
		const submission = state.submission

		if (submission.id && submission.name) {
			return submission
		}

		return null
	},
	getPlacesOfService(state: IWorklistState) {
		return state.fields.placesOfService
	},
	getPlans(state: IWorklistState) {
		return state.plans
	},
	getSubmissionConfigurations(state: IWorklistState) {
		return state.submissionConfigurations
	},
}

const actions = {
	setForm({ commit }: ActionContext<IWorklistState, any>, form: IForm) {
		commit('setForm', form)
		commit('setSubmission', null)
	},
	setSubmission(
		{ state, commit }: ActionContext<IWorklistState, any>,
		submission: ISubmissionConfiguration
	) {
		commit('setForm', null)
		commit('setSubmission', submission)

		const configurations = moveMatchedConfigurationOption(
			submission.id,
			state.submissionConfigurations
		) as ISubmissionConfiguration[]

		commit('setSubmissionConfigurations', configurations)
	},
	createFormList({ state }: any, options: IForm[]) {
		const form = state.form
		if (form && form.id && form.name) {
			return moveMatchedConfigurationOption(form.id, options) as IForm[]
		}
		return options
	},
	setPlacesOfService(
		{ commit }: ActionContext<IWorklistState, any>,
		placesOfService: string[]
	) {
		commit('setPlacesOfService', placesOfService)
	},
	setPlans(
		{ commit }: ActionContext<IWorklistState, any>,
		plans: IPublishedPlanAssociation[]
	) {
		commit('setPlans', plans)
	},
	setSubmissionConfiguration(
		{ commit }: ActionContext<IWorklistState, any>,
		configuration: any
	) {
		commit('setSubmissionConfiguration', configuration)
	},
	setSubmissionConfigurations(
		{ commit }: ActionContext<IWorklistState, any>,
		configurations: any[]
	) {
		commit('setSubmissionConfigurations', configurations)
	},
}

// Store user selected configuration for easier future selections
const moveMatchedConfigurationOption = (
	id: string,
	options: IConfigurationOption[]
) => {
	return [
		...options.filter(option => option.id === id),
		...options.filter(option => option.id !== id),
	]
}

export default {
	namespaced: true,
	state: getInitialState,
	getters,
	mutations,
	actions,
}

import axios from 'axios'
import interceptor from './interceptor'
import queryString from 'query-string'

import {
	ICategoryData,
	IConfiguration,
	IContactFaxMethod,
	IContactPhoneMethod,
	IContactPortalMethod,
	IPayerData,
	IProcedureCodeData,
	IWorklist,
	IWorklistCreate,
	IWorklistFilterData,
	IWorklistItem,
	IWorklistItemData,
	IWorklistItemFilter,
	IWorklistItemsData,
	IWorklistProcedureCode,
	IWorklists,
	IWorklistUser,
} from '../../server/api/models/fe/worklist'

import {
	IResponsePayersGET,
	IResponseProcedureCodesGET,
	IRequestWorklistPUT,
	IRequestAssetsGET,
	IResponseAssetsGET,
	IResponseWorklistUser,
} from '@myndshft/types-mc-api'

import { IAsset } from '../../server/api/models/fe/shared'
import { fromResponseAsset } from '../../server/api/models/mappers/from-server/payer'
import { fromResponsePayer } from '../../server/api/models/mappers/from-server/worklist'
import { getBff, getApiServerV2 } from './shared.http'

import store from '@/store'
import {
	fromResponseProcedureCode,
	fromResponseWorklistItemFaxes,
	fromResponseWorklistItemPhones,
	fromResponseWorklistItemPortals,
} from '@/models/mappers/from-server/worklist'
import { fromFeWorklistItemFilter } from '@/models/mappers/from-fe/worklist'
import { IForm, ISubmissionConfiguration } from '@/models/worklists/outcome'

axios.interceptors.request.use(interceptor as any)

export async function createWorklist(worklist: IWorklistCreate): Promise<void> {
	const path = `${getBff()}/worklist`
	await axios.post(path, worklist)
}

export async function fetchPayers(
	page: number,
	query: string = ''
): Promise<IPayerData> {
	const payerQuery = query ? `&name=${query}` : ''
	const path = `${getApiServerV2()}/payers?include=pa_summary&page=${page}${payerQuery}`
	const response = await axios.get<IResponsePayersGET>(path)
	return {
		payers: response.data.payers
			? response.data.payers.map(fromResponsePayer)
			: [],
		totalPages: response.data.total_pages,
		total: response.data.total,
	}
}

export async function fetchWorklistDataFilters(
	id: string
): Promise<IWorklistFilterData> {
	const path = `${getBff()}/worklist_data_filters/${id}`
	const response = await axios.get<IWorklistFilterData>(path)

	return response.data
}

export async function fetchProcedures(
	page: number,
	query: string = '',
	categoryId: string = ''
): Promise<IProcedureCodeData> {
	const procedureQuery = query ? `&code=${query}` : ''
	const procedureCategory = categoryId ? `&category_id=${categoryId}` : ''
	const path = `${getApiServerV2()}/procedures?page=${page}${procedureQuery}${procedureCategory}`
	const response = await axios.get<IResponseProcedureCodesGET>(path)

	return {
		procedureCodes: response.data.procedures
			? response.data.procedures.map(fromResponseProcedureCode)
			: [],
		total: response.data.total,
		totalPages: response.data.total_pages,
	}
}

export async function fetchAddlProcedures(
	page: number
): Promise<IWorklistProcedureCode[]> {
	const path = `${getApiServerV2()}/procedures?page=${page}`
	const response = await axios.get<IResponseProcedureCodesGET>(path)
	return response.data.procedures.map(fromResponseProcedureCode)
}

export async function fetchWorklists(
	perPage: number,
	page: number,
	query: string,
	userName: string
): Promise<IWorklists> {
	const qs = queryString.stringify({
		per_page: perPage,
		page,
		name: query,
		user_name: userName,
	})
	const path = `${getBff()}/worklists?${qs}`
	const response = await axios.get(path)
	return response.data
}

export async function updateWorklist(worklist: IWorklist): Promise<void> {
	const path = `${getBff()}/worklist/${worklist.id}`
	await axios.put<IRequestWorklistPUT>(path, worklist)
}

export async function fetchProcedureCategories(): Promise<ICategoryData> {
	const path = `${getBff()}/procedure_categories`
	const response = await axios.get<ICategoryData>(path)
	return response.data
}

export async function fetchDiagnosisCodes(): Promise<any> {
	const path = `${getBff()}/diagnosis_codes`
	const response = await axios.get<any>(path)
	return response.data
}

export async function fetchWorklist(id: string): Promise<IWorklist> {
	const path = `${getBff()}/worklist/${id}`
	const response = await axios.get<IWorklist>(path)
	return response.data
}

export async function fetchWorklistItems(
	page: number,
	sort: string,
	order: string,
	worklistId: string,
	filters: IWorklistItemFilter
): Promise<IWorklistItemsData> {
	const params = fromFeWorklistItemFilter(filters, page, sort, order)
	const path = `${getBff()}/worklist/${worklistId}/items`
	const response = await axios.get(path, { params })

	return response.data
}

export async function fetchWorklistConflicts(
	worklistId: string,
	pageToken?: string | null
) {
	const path = `${getBff()}/worklist/${worklistId}/conflicts${
		pageToken ? `?page_token=${pageToken}` : ''
	}`
	const response = await axios.get(path)
	return response.data
}

export async function fetchWorklistItem(
	worklistId: string,
	id: string
): Promise<IWorklistItemData> {
	const path = `${getBff()}/worklist/${worklistId}/items/${id}`
	const response = await axios.get<IWorklistItemData>(path)
	return response.data
}

export async function fetchItemPhones(
	payerId: string,
	planId: string
): Promise<any> {
	const phones: IContactPhoneMethod[] = []
	let page = 0
	let done = false

	while (!done) {
		const path = `${getApiServerV2()}/payers/${payerId}/contact_methods?plan_id=${planId}&type=PHONE&page=${page}`
		const response: any = await axios.get<any>(path)

		phones.push(
			...response.data.contact_methods.map(fromResponseWorklistItemPhones)
		)

		page += 1
		done = page >= response.data.total_pages
	}

	return phones
}

function buildFormsPath(search: string | null, ids: string[] | null) {
	const queryParams: string[] = []
	const basePath = `${getBff()}/forms`

	if (search) {
		queryParams.push(`search=${search}`)
	}

	if (ids?.length) {
		const uniqueIds = [...new Set(ids)]
		const idsParams = uniqueIds.map(id => `ids=${id}`).join('&')

		queryParams.push(idsParams)
	}

	return queryParams.length ? `${basePath}?${queryParams.join('&')}` : basePath
}

async function storeFormList(data: { forms: [{ id: string; name: string }] }) {
	return await store
		.dispatch('worklist/createFormList', data?.forms)
		.then((options: IForm[]) => {
			return options || []
		})
}

export async function fetchForms(
	search: string | null,
	ids: string[] | null
): Promise<IConfiguration[]> {
	const path = buildFormsPath(search, ids)

	try {
		const response = await axios.get(path)

		return storeFormList(response?.data)
	} catch (error) {
		throw new Error('Error fetching forms')
	}
}

export async function fetchSubmissionConfigurations(): Promise<
	IConfiguration[]
> {
	const path = `${getBff()}/submission-configurations`

	const response: any = await axios.get<any>(path).catch(_ => {
		throw new Error('Error fetching submission configurations')
	})

	return response?.data?.configurations
}

export async function fetchSubmissionConfiguration(
	ids: string[]
): Promise<IConfiguration[]> {
	const configurations: IConfiguration[] = []

	for (const id of ids) {
		const submissionConfigurations =
			store.getters['worklist/getSubmissionConfigurations']
		const config = submissionConfigurations.find(config => config.id === id)

		// Only fetch configurations that have not been stored
		if (!config) {
			const path = `${getBff()}/submission-configuration?id=${id}`

			const response = await axios.get<IConfiguration>(path).catch(_ => {
				throw new Error(`Error fetching submission configuration for id: ${id}`)
			})

			configurations.push(response.data)

			store.dispatch('worklist/setSubmissionConfiguration', response.data)
		} else {
			configurations.push(config)
		}
	}

	return configurations
}

export async function fetchSubmissionConfigurationQuery(
	query: string
): Promise<IConfiguration[]> {
	if (!query) {
		return []
	}

	const path = `${getBff()}/submission-configuration-query?query=${encodeURIComponent(
		query
	)}`

	const response: any = await axios.get<any>(path).catch(_ => {
		throw new Error('Error fetching submission configurations by query')
	})

	return response?.data?.configurations
}

export async function fetchAssets(
	payerId: string,
	params: IRequestAssetsGET
): Promise<IAsset[]> {
	let page = 0
	let done = false
	const responses: IAsset[] = []

	while (!done) {
		const path = `${getApiServerV2()}/payers/${payerId}/assets?page=${page}`
		await axios
			.get<IResponseAssetsGET>(path, { params })
			.then(response => {
				responses.push(...response.data.assets.map(fromResponseAsset))
				page += 1
				done = page > response.data.total_pages
			})
			.catch(() => {
				done = true
			})
	}

	return responses
}

export async function fetchItemFaxes(
	payerId: string,
	planId: string
): Promise<any> {
	const faxes: IContactFaxMethod[] = []
	let page = 0
	let done = false

	while (!done) {
		const path = `${getApiServerV2()}/payers/${payerId}/contact_methods?plan_id=${planId}&type=FAX&page=${page}`
		const response: any = await axios.get<any>(path)

		faxes.push(
			...response.data.contact_methods.map(fromResponseWorklistItemFaxes)
		)

		page += 1
		done = page >= response.data.total_pages
	}

	return faxes
}

export async function fetchItemPortals(
	payerId: string,
	planId: string
): Promise<any> {
	const portals: IContactPortalMethod[] = []
	let page = 0
	let done = false

	while (!done) {
		const path = `${getApiServerV2()}/payers/${payerId}/contact_methods?plan_id=${planId}&type=PORTAL&page=${page}`
		const response: any = await axios.get<any>(path)

		portals.push(
			...response.data.contact_methods.map(fromResponseWorklistItemPortals)
		)

		page += 1
		done = page >= response.data.total_pages
	}

	return portals
}

export async function fetchWorklistUsers(): Promise<IWorklistUser[]> {
	const path = `${getBff()}/auth/users`
	const response: any = await axios.get<IResponseWorklistUser>(path)
	return response.data
}

export async function updateWorklistItem(
	worklistId: string,
	notify: boolean,
	assigneeId: string,
	item: IWorklistItem
): Promise<IWorklistItem> {
	const path = `${getBff()}/worklist/${worklistId}/items/${
		item.id
	}?notify=${notify}&assigneeId=${assigneeId}`
	const response = await axios.put(path, item)
	return response.data
}

export async function publishWorklistItems(
	worklistId: string,
	worklistItems: string[]
): Promise<any> {
	const path = `${getBff()}/worklist/${worklistId}/items/publish`
	return await axios.post(path, { item_ids: worklistItems })
}

export async function deleteWorklistItems(
	worklistId: string,
	worklistItems: IWorklistItem[]
): Promise<any> {
	const path = `${getBff()}/worklist/${worklistId}/items/delete`
	return await axios.post(path, worklistItems)
}

export async function deleteWorklist(id: string): Promise<void> {
	const path = `${getBff()}/worklist/${id}`
	return await axios.delete(path)
}
